<template lang="html">
  <div>
    <!-- DIRECT CHAT PRIMARY -->
    <div v-if="noUser" class="card card-sucress cardutline direct-chat direct-chat-secondary mb-0">
      <div class="card-header p-2">
        <h3 class="card-title">聊天会话</h3>

        <div class="card-tools">
          <!--<span data-toggle="tooltip" title="3 New Messages" class="badge bg-success">3</span>-->
          <!--<button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
          </button>-->
          <!--<button type="button" class="btn btn-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
              <i class="fas fa-comments"></i>
          </button>-->
          <router-link :to="{name:'MessageList'}" class="btn btn-tool">
            <i class="fas fa-times"></i>
          </router-link>
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <!-- Conversations are loaded here -->
        <div class="direct-chat-messages">
          <div v-if="hasMore" class="text-center">
            <a @click="AppendData">获取更多…</a>
          </div>
          <div v-else class="text-center">
            前面没有了
          </div>
          <template v-for="(msg) in messages">
            <!-- Message. Default to the left -->
            <a :name="'msg_'+ msg.addTime" :id="'msg_'+ $moment(msg.addTime).unix()" />
            <div v-if="msg.user.userName != currentUser.userName" class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left">{{msg.user.nickName}}</span>
                <time class="direct-chat-timestamp ml-2" :title="$moment(msg.addTime).format('YYYY-MM-DD HH:mm:ss')">{{formatTime(msg.addTime)}}</time>
              </div>
              <!-- /.direct-chat-info -->
              <img v-if="msg.user.avatarUrl" class="direct-chat-img" :src="msg.user.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')" alt="Message User Image">
              <img v-else class="direct-chat-img" src="/images/avatar-default.jpg" alt="Message User Image">
              <!-- /.direct-chat-img -->
              <div class="direct-chat-text">
                {{msg.text}}
              </div>
              <!-- /.direct-chat-text -->
            </div>
            <!-- /.direct-chat-msg -->
            <!-- Message to the right -->
            <div v-else class="direct-chat-msg right">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-right">{{msg.user.nickName}}</span>
                <time class="direct-chat-timestamp float-right mr-2" :title="$moment(msg.addTime).format('YYYY-MM-DD HH:mm:ss')">{{formatTime(msg.addTime)}}</time>
              </div>
              <!-- /.direct-chat-info -->
              <img v-if="msg.user.avatarUrl" class="direct-chat-img" :src="msg.user.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')" alt="Message User Image">
              <img v-else class="direct-chat-img" src="/images/avatar-default.jpg" alt="Message User Image">
              <!-- /.direct-chat-img -->
              <div class="direct-chat-text">
                {{msg.text}}
              </div>
              <!-- /.direct-chat-text -->
            </div>
            <!-- /.direct-chat-msg -->
          </template>
        </div>
        <!--/.direct-chat-messages-->
        <!-- Contacts are loaded here -->
        <div v-if="false" class="direct-chat-contacts">
          <ul class="contacts-list">
            <li>
              <a href="">
                <img class="contacts-list-img" src="">
                <div class="contacts-list-info">
                  <span class="contacts-list-name">
                    Count Dracula
                    <small class="contacts-list-date float-right">2/28/2015</small>
                  </span>
                  <span class="contacts-list-msg">How have you been? I was...</span>
                </div>
                <!-- /.contacts-list-info -->
              </a>
            </li>
            <!-- End Contact Item -->
          </ul>
          <!-- /.contatcts-list -->
        </div>
        <!-- /.direct-chat-pane -->
      </div>
      <!-- /.card-body -->
      <div class="card-footer p-1">
        <form @submit.prevent="send">
          <div class="input-group input-group-sm">
            <input type="text" name="message" placeholder="在这里输入消息 ..." class="form-control" v-model.trim:value="message.text" :disabled="updateing" @focus="setReaded">
            <span class="input-group-append">
              <button type="submit" class="btn btn-primary" :disabled="updateing"><i v-if="updateing" class="fas fa-spinner fa-pulse"></i>发送</button>
            </span>
          </div>
        </form>
      </div>
      <!-- /.card-footer-->
    </div>
    <!--/.direct-chat -->
    <div v-else class="alert alert-danger" role="alert">
      <h5 class="alert-heading"><i class="icon fas fa-exclamation-triangle"></i>联系人不存在</h5>
      <p style="text-align:justify;text-indent:2em;">
        您访问了一个不存在的联系人<br /> <router-link :to="{name:'MessageList'}">点击此处回到列表</router-link>
      </p>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Modal } from 'common'
  //import Modal from '@/components/Modal'
  export default {
    components: {
      Modal
    },
    data() {
      return {
        list: [],
        message: {
          toUserName: this.$route.params.userName,
          text: null
        },
        messages: [],
        form: {
          message: ''
        },
        fields: [
          { key: 'active', label: '操作' },
          { key: 'type', label: '类型', sortable: true, sortDirection: 'desc' },
          { key: 'name', label: '名称', sortable: true, sortDirection: 'asc' },
          { key: 'addTime', label: '添加时间', sortable: true, sortDirection: 'asc' },
          { key: 'closed', label: '关闭', sortable: true, sortDirection: 'asc' },
        ],
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 20,
          sort: "Id",
          order: "desc",
          fields: 'Name',
          key: null,
          roles: "",
          type: ''
        },
        types: [],
        updateing: false,
        hasMore: true,
        noUser: true,
      }
    },
    computed: {
      ...mapGetters([
        'currentUser'
      ]),
    },
    created() {
      var i = setInterval(() => {
        if (!this.currentUser.userName) { return; }
        clearInterval(i)
        if (this.$route.params.userName == this.currentUser.userName) {
          this.$message({
            message: '你不能给自己发私信',
            type: 'error',
            zIndex: 9999,
            //showClose: true
          })
          this.$router.push({ name: 'Home' })
        }
      }, 100)
      setInterval(() => {
        this.messages = [...this.messages]//使vue重新渲染聊天框数据
      }, 10000)
      this.loadData()
      //this.loadTypes()
      this.$mainHub.$on('chat-message-received', this.onMessageReceived)
      this.$mainHub.$on('message-received', this.onMessageReceived)

      //this.$mgr.getAcessToken().then(
      //    acessToken => {
      //        if (acessToken) {
      //            this.startSignalR(acessToken)
      //        }
      //    },
      //    err => {
      //        console.log(err)
      //    }
      //)

    },
    beforeDestroy() {
      // Make sure to cleanup SignalR event handlers when removing the component
      this.$mainHub.$off('chat-message-received', this.onMessageReceived)
      this.$mainHub.$off('message-received', this.onMessageReceived)
    },
    methods: {
      onMessageReceived({ message }) {
        console.log(message)
        if (message.user.userName == this.$route.params.userName || message.user.userName == this.currentUser.userName) {
          this.messages = [...this.messages, message]
          var div = document.querySelector(".direct-chat-messages")
          setTimeout(() => { div.scrollTop = div.scrollHeight }, 1)
        }
      },
      onSendMessage(evt) {
        this.$mainHub.sendMessage(this.message)
        this.message.text = ''
      },
      onHidden() {
        Object.assign(this.form, {
          message: ''
        })
      },
      loadData() {
        var url = "/Api/My/Messages/Talks"
        var params = {
          toUserName: this.message.toUserName,
          pageSize: this.query.pageSize,
        }
        this.$axios.get(url, { params: params })
          .then((response) => {
            console.log(response)
            this.messages = (response.data || []).reverse()
            var div = document.querySelector(".direct-chat-messages")
            setTimeout(() => { div.scrollTop = div.scrollHeight }, 1)
            this.hasMore = response.data.length == params.pageSize;
            this.setReaded();
          }).catch((error) => {
            this.$message({
              message: error.response.data,
              type: 'error',
              zIndex: 9999,
              //showClose: true
            })
            this.noUser = false
            //console.log('skdjhfdsk', { ...error })
          })
      },
      AppendData() {
        var url = "/Api/My/Messages/Talks"
        var params = {
          toUserName: this.message.toUserName,
          pageSize: this.query.pageSize,
          lastTime: this.messages[0].addTime
        }
        this.$axios.get(url, { params: params })
          .then((response) => {
            this.messages = [...(response.data || []).reverse(), ...this.messages]
            this.hasMore = response.data.length == params.pageSize;
            var div = document.querySelector(".direct-chat-messages")
            setTimeout(() => {
              var obj = document.querySelector('#msg_' + this.$moment(params.lastTime).unix())
              $(div).scrollTop(obj.offsetTop - 32)
              //$(div).animate({ scrollTop: obj.offsetTop-32 }, 0)
              //$(div).fadeIn(1000);
            }, 1)
          }).catch((error) => {
            console.log(error)
          })
      },
      search() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      send() {
        if (this.message.text == '' || this.message.text == null) {
          return
        }
        this.updateing = true;
        this.$axios.post('/Api/My/Messages', this.message)
          .then((response) => {
            this.message.text = ''
            //this.reset()
            //this.$refs.Edit.hide()
            //this.loadData()
            this.updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false;
          })
      },
      setReaded() {
        this.$axios.get('/Api/My/Messages/Readed/' + this.message.toUserName)
          .then((response) => {
          })
          .catch((error) => {
            console.log(error)
          })
      },
      formatTime(time) {
        var mTime = this.$moment(time)
        if (mTime.toDate() < this.$moment().subtract(6, 'days').toDate()) {
          return mTime.format("YYYY-MM-DD HH:mm:ss")
        }
        else if (mTime.toDate() < this.$moment().subtract(1, 'days').toDate()) {
          return mTime.format("dddd HH:mm:ss")
        }
        else if (mTime.toDate() < this.$moment().subtract(4, 'hours').toDate()) {
          return mTime.format("HH:mm:ss")
        }
        else {
          return mTime.fromNow()
        }
      }

    },
    beforeRouteUpdate(to, from, next) {
      this.message.toUserName = to.params.userName
      this.loadData()
      next()
    }
  }
</script>
<style lang="css" scoped>
  .direct-chat-messages {
    height: calc(100vh - 150px);
  }

  .direct-chat-text {
    margin-left: 10px;
    display: inline-block;
    max-width: calc(100% - 50px);
  }

  .right .direct-chat-text {
    margin-right: 10px;
    margin-left: auto;
    float: right;
  }
</style>
